import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--select"
export default class extends Controller {
  connect() {
    
    new TomSelect(this.element,{
        maxOptions:null,
        user_created:true
    })
     
  }
  
}